.custom-date-picker input[type="date"] {

}


.custom-date-picker input[type="date"]::-webkit-calendar-picker-indicator {
width: 100%;
height: 100px;
}

.custom-date-picker input[type="date"]::-webkit-inner-spin-button,
.custom-date-picker input[type="date"]::-webkit-clear-button {

}
/* .arrowDown,
.arrowUp {
  display: none !important;

} */
.flatpickr-current-month{
    display: flex !important;
    gap: 14px !important;
    /* border: 1px solid black; */
    align-items: center !important;
    justify-content: center !important;

}